<style lang="scss" scoped>
    tr {
        &.link {
            cursor: pointer;
        }
    }
</style>

<template>
    <div>
        <v-container style="max-width: 1400px">           
            <v-row>
                <v-col>
                    <v-card flat color="transparent">
                        <v-card-title class="text-h4 mb-10">
                            {{ __('Supervisão') }}
                            <v-btn @click="getNotificationsByManager()" icon large>
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </v-card-title>

                        <v-row class="mb-10" dense>
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <v-skeleton-loader v-if="loading" type="image" />
                                <v-list-item v-else-if="notifications_total" link @click="setAllActiveDrawer">
                                    <v-list-item-content>
                                        <v-list-item-subtitle class="text-h4">
                                            {{ notifications_total.unread }}
                                        </v-list-item-subtitle>
                                        <v-list-item-title>
                                            {{ __('Todas ativas') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="mt-1">
                                            <SectionBar 
                                                :label="__('Detalhes')"
                                                :items="[
                                                    {
                                                        color: '#059862',
                                                        value: notifications_total.per_period.green.perc,
                                                        label: '0-1 ' + __('Dia'),
                                                        total:  notifications_total.per_period.green.total
                                                    },
                                                    {
                                                        color: '#ffc107',
                                                        value: notifications_total.per_period.orange.perc,
                                                        label: '2-4 ' + __('Dias'),
                                                        total: notifications_total.per_period.orange.total
                                                    },
                                                    {
                                                        color: '#f55246',
                                                        value: notifications_total.per_period.red.perc,
                                                        label: '+5 ' + __('Dias'),
                                                        total:  notifications_total.per_period.red.total
                                                    },
                                                ]" 
                                            />
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <v-skeleton-loader v-if="loading" type="image"  />
                                <v-list-item v-else-if="without_manager" link @click="setNoManagerDrawer">
                                    <v-list-item-content>
                                        <v-list-item-subtitle class="text-h4">
                                            {{ without_manager.unread }}
                                        </v-list-item-subtitle>
                                        <v-list-item-title>
                                            {{ __('Sem responsável') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="mt-1">
                                            <SectionBar 
                                                :label="__('Detalhes')"
                                                :items="[
                                                    {
                                                        color: '#059862',
                                                        value: without_manager.per_period.green.perc,
                                                        label: '0-1 ' + __('Dia'),
                                                        total: without_manager.per_period.green.total
                                                    },
                                                    {
                                                        color: '#ffc107',
                                                        value: without_manager.per_period.orange.perc,
                                                        label: '2-4 ' + __('Dias'),
                                                        total: without_manager.per_period.orange.total
                                                    },
                                                    {
                                                        color: '#f55246',
                                                        value: without_manager.per_period.red.perc,
                                                        label: '+5 ' + __('Dias'),
                                                        total: without_manager.per_period.red.total
                                                    },
                                                ]" 
                                            />
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" @click="setBlockedManagerDrawer">
                                <v-skeleton-loader v-if="loading" type="image"  />
                                <v-list-item v-else-if="with_blocked_manager" link>
                                    <v-list-item-content>
                                        <v-list-item-subtitle class="text-h4">
                                            {{ with_blocked_manager.unread }}
                                        </v-list-item-subtitle>
                                        <v-list-item-title>
                                            {{ __('Com responsável bloqueado') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="mt-1">
                                            <SectionBar 
                                                :label="__('Detalhes')" 
                                                :items="[
                                                    {
                                                        color: '#059862',
                                                        value: with_blocked_manager.per_period.green.perc,
                                                        label: '0-1 ' + __('Dia'),
                                                        total: with_blocked_manager.per_period.green.total
                                                    },
                                                    {
                                                        color: '#ffc107',
                                                        value: with_blocked_manager.per_period.orange.perc,
                                                        label: '2-4 ' + __('Dias'),
                                                        total: with_blocked_manager.per_period.orange.total
                                                    },
                                                    {
                                                        color: '#f55246',
                                                        value: with_blocked_manager.per_period.red.perc,
                                                        label: '+5 ' + __('Dias'),
                                                        total: with_blocked_manager.per_period.red.total
                                                    },
                                                ]" 
                                            />
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>


                        <v-skeleton-loader v-if="loading" type="table: table-thead, table-tbody" />
                        <v-simple-table v-else>
                            <thead>
                                <tr>
                                    <th>
                                        {{ __('Gestor') }}
                                    </th>
                                    <th class="text-center">
                                        {{ __('Leads') }}
                                    </th>
                                    <th class="text-center">
                                        {{ __('Notificações') }}
                                    </th>
                                    <th>
                                        {{ __('Status') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="notifications_by_manager">
                                <tr 
                                    v-for="manager in notifications_by_manager" 
                                    :key="manager.id" 
                                    class="link" 
                                    @click="setManagerDrawer(manager)"
                                >
                                    <td v-if="manager.manager">
                                        <span 
                                          :class="{ 'text-decoration-line-through': !manager.manager.user.is_active }"
                                        >{{ manager.manager.user.name }}</span>
                                    </td>
                                    <td v-else class="grey--text">
                                        {{ __('Sem gestor') }}
                                    </td>
                                    <td class="text-center">
                                        {{ manager.leads }}
                                    </td>
                                    <td class="text-center">
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                          <span class="font-weight-bold blue--text" v-on="on" v-bind="attrs">{{ manager.unread_groups }}</span> 
                                        </template>
                                        <span>{{ __('Notificações agrupadas') }}</span>
                                      </v-tooltip>
                                      <v-icon left right small>mdi-chevron-right</v-icon>
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                          <span v-on="on" v-bind="attrs">{{ manager.unread }}</span> 
                                        </template>
                                        <span>{{ __('Total de notificações') }}</span>
                                      </v-tooltip>
                                    </td>
                                    <td with="200px">
                                        <SectionBar 
                                            :label="__('Detalhes')"
                                            :items="[
                                                {
                                                    color: '#059862',
                                                    value: manager.per_period.green.perc,
                                                    label: '0-1 ' + __('Dia'),
                                                    total: manager.per_period.green.total
                                                },
                                                {
                                                    color: '#ffc107',
                                                    value: manager.per_period.orange.perc,
                                                    label: '2-4 ' + __('Dias'),
                                                    total: manager.per_period.orange.total
                                                },
                                                {
                                                    color: '#f55246',
                                                    value: manager.per_period.red.perc,
                                                    label: '+5 ' + __('Dias'),
                                                    total: manager.per_period.red.total
                                                },
                                            ]" 
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <notification-leads-drawer
          v-model="drawer_open"
          :title="drawer_title"
          @close="closeDrawer"
          @getData="drawer_get_data"
          :manager="drawer_manager"
          @refresh="getNotificationsByManager"
        />
    </div>
</template>

<script>
import { 
  analytics_notifications_by_manager, 
  all_notifications,
  without_manager_notifications,
  with_blocked_manager_notifications,
  notifications_by_manager
} from '@/services/supervision/lead_notifications';
import SectionBar from "@/components/SectionBar";
import NotificationLeadsDrawer from "@/components/Supervision/NotificationLeadsDrawer";
import i18n from '@/mixins/i18n'

export default {
    components: { SectionBar, NotificationLeadsDrawer },

    mixins: [i18n],

    data() {
        return {
            i18n_prefix: 'supervision',
            notifications_by_manager: [],
            alerts: [],
            notifications_total: {},
            without_manager: {},
            with_blocked_manager: {},
            loading: false,

            // Drawer
            drawer_open: false,
            drawer_title: '',
            drawer_get_data: () => ({}),
            drawer_manager: null
        }
    },

    methods: {
        clear() {
            this.notifications_by_manager = null;
            this.alerts = null;
            this.notifications_total = null;
            this.without_manager = null;
            this.with_blocked_manager = null;
        },  
        async getNotificationsByManager() {
            this.clear();
            this.loading = true;
            const { data, metadata } = await analytics_notifications_by_manager();
            this.loading = false;

            this.notifications_by_manager = data;
            this.alerts = metadata.alerts;
            this.notifications_total = metadata.total;
            this.without_manager = metadata.without_manager;
            this.with_blocked_manager = metadata.with_blocked_manager;
        },

        // Drawer
        setAllActiveDrawer() {
            this.drawer_open = true;
            this.drawer_title = this.__('Todas ativas');
            this.drawer_get_data = all_notifications; 
            this.drawer_manager = null;
        },
        setNoManagerDrawer() {
            this.drawer_open = true;
            this.drawer_title = this.__('Sem responsável');
            this.drawer_get_data = without_manager_notifications; 
            this.drawer_manager = null;
        },
        setBlockedManagerDrawer() {
            this.drawer_open = true;
            this.drawer_title = this.__('Com responsável bloqueado');
            this.drawer_get_data = with_blocked_manager_notifications; 
            this.drawer_manager = null;
        },
        setManagerDrawer(manager) {
            this.drawer_open = true;
            this.drawer_title = manager.manager.user.name;
            this.drawer_get_data = (params) => notifications_by_manager(manager.manager.id, params);
            this.drawer_manager = manager.manager;
        },

        closeDrawer() {
            this.drawer_open = false;
            this.drawer_title = '';
        }
    },

    created() {
        this.getNotificationsByManager();
    },
}
</script>