<template>
  <div>
    <v-data-table
      :headers="visibleHeaders"
      :options.sync="localOptions"
      :show-select="!readonly"
      show-expand
      :items="items"
      :value="value"
      @input="$emit('input', $event)"
      :loading="loading"
      :server-items-length="totalItems"
    >
      <template v-slot:expanded-item="{ item }">
        <td :colspan="visibleHeaders.length + 2">
          <List :items="item.notifications" :dense="true" />
        </td>
      </template>

      <template v-slot:item.notifications="{ item }">
        {{ item.notifications.length }}
      </template>

      <template v-slot:item.manager="{ item }">
        <span
          v-if="item.service_manager"
          :class="{ 'text-decoration-line-through': !item.service_manager.is_active }"
        >{{ item.service_manager.name }}</span>
      </template>

      <template v-slot:item.first_notification_date="{ item }">
        {{ formatDate(item.first_notification_date) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatDateTime } from '@/tools/date'; 
import List from '@/components/Lead/Notification/List';
import i18n from '@/mixins/i18n';

export default {
  name: 'notification-leads-table',
  components: { 
    List
  },
  mixins: [i18n],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    totalItems: {
      type: Number
    },
    hiddenColumns: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object
    }
  },
  data() {
    return {
      i18n_prefix: 'supervision',
      localOptions: this.options
    }
  },
  methods: {
    formatDate(date) {
      return formatDateTime(date);
    }
  },
  computed: {
    visibleHeaders() {
      if (this.hiddenColumns.length > 0) {
        return this.headers.filter(header => !this.hiddenColumns.includes(header.value));
      }

      return this.headers;
    },
    headers() {
      return [
        {
          value: 'identifier_code',
          text: this.__('Lead'),
          sortable: false
        },
        {
          value: 'manager',
          text: this.__('Gestor'),
          sortable: false
        },
        {
          value: 'notifications',
          text: this.__('Notificações'),
          sortable: false
        },
        {
          value: 'first_notification_date',
          text: this.__('Notificação mais antiga'),
          sortable: !this.readonly
        }
      ]
    },
  },
  watch: {
    localOptions(value) {
      this.$emit('update:options', value);
    }
  }
}
</script>

<style>

</style>