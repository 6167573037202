var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.value,"persistent":"","width":"450px","scrollable":""},on:{"change":_vm.handleChange}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.__('Atribuir responsável'))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleClose()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.__('Gestor responsável pelos processos'))+" ")]),_c('v-form',{attrs:{"disabled":_vm.loading_submit},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmitForm.apply(null, arguments)}}},[_c('v-card-text',{staticClass:"px-8 pt-0"},[_c('v-switch',{attrs:{"label":_vm.__('Não atribuir nenhum gestor aos processos')},model:{value:(_vm.noManager),callback:function ($$v) {_vm.noManager=$$v},expression:"noManager"}})],1),(!_vm.noManager)?_c('v-card-text',{staticClass:"pt-0"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{staticClass:"px-3",attrs:{"label":_vm.__('Pesquisar por nome'),"hide-details":"","prepend-inner-icon":"mdi-magnify","solo":"","autofocus":"","clearable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();$event.stopPropagation();return _vm.getManagers()},"click:clear":function($event){return _vm.clearFilter()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"items":_vm.managers,"value":[_vm.manager],"show-select":_vm.canUpdateManager,"loading":_vm.loading_managers,"single-select":"","headers":[
              {
                text: _vm.__('Gestor'),
                value: 'name'
              },
              {
                text: _vm.__('Status'),
                value: 'is_active'
              },
            ],"item-class":item => !item.is_active ? 'red--text' : ''},on:{"input":function($event){_vm.manager = $event[0] || null}},scopedSlots:_vm._u([{key:"item.is_active",fn:function({ item }){return [_vm._v(" "+_vm._s(item.is_active ? _vm.__('Ativo') : _vm.__('Bloqueado'))+" ")]}}],null,false,4254548773)})],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.handleClose()}}},[_vm._v(_vm._s(_vm.__('Cancelar')))]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.disableSubmit,"loading":_vm.loading_submit},on:{"click":function($event){return _vm.handleSubmitForm()}}},[_vm._v(_vm._s(_vm.__('Confirmar')))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }