<template>
  <v-dialog 
    :value="value" 
    @change="handleChange" 
    persistent
    width="450px"
    scrollable
  >
    <v-card>
        <v-card-title>
          {{ __('Atribuir responsável') }}
          <v-spacer />
          <v-btn @click="handleClose()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle> 
          {{ __('Gestor responsável pelos processos') }}
        </v-card-subtitle>
        <v-form @submit.prevent="handleSubmitForm" :disabled="loading_submit">
          <v-card-text class="px-8 pt-0">
            <v-switch
              v-model="noManager"
              :label="__('Não atribuir nenhum gestor aos processos')"
            />
          </v-card-text>
          <v-card-text class="pt-0" v-if="!noManager">
            <v-row dense>
              <v-col>
                <v-text-field 
                  v-model="search"
                  @keydown.enter.prevent.stop="getManagers()"
                  @click:clear="clearFilter()"
                  class="px-3" 
                  :label="__('Pesquisar por nome')" 
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  solo
                  autofocus
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table 
              :items="managers"
              :value="[manager]"
              @input="manager = $event[0] || null" 
              :show-select="canUpdateManager"
              :loading="loading_managers"
              single-select
              :headers="[
                {
                  text: __('Gestor'),
                  value: 'name'
                },
                {
                  text: __('Status'),
                  value: 'is_active'
                },
              ]"
              :item-class="item => !item.is_active ? 'red--text' : ''"
            >
              <template v-slot:item.is_active="{ item }">
                {{ item.is_active ? __('Ativo') : __('Bloqueado') }}
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="handleClose()" text>{{ __('Cancelar') }}</v-btn>
            <v-btn 
              @click="handleSubmitForm()" 
              color="primary" 
              :disabled="disableSubmit"
              :loading="loading_submit"
            >{{ __('Confirmar') }}</v-btn>
          </v-card-actions>
        </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { index as indexManagers } from "@/services/service_managers";
import { assign_manager } from "@/services/supervision/lead_notifications";
import i18n from '@/mixins/i18n';

export default {
  name: "assign-manager-dialog",
  props: {
    value: {
      type: Boolean
    },
    selectedLeads: {
      type: Array,
      default: () => []
    }
  },
  mixins: [i18n],
  data() {
    return {
      i18n_prefix: 'managers',
      loading_submit: false,
      loading_managers: false,
      managers: [],
      search: "",
      manager: null,

      noManager: false
    };
  },
  methods: {
    handleChange($event) {
      this.$emit("input", $event);
    },
    handleClose() {
      this.$emit("input", false);
      this.resetStates();
    },

    resetStates() {
      this.managers = [];
      this.search = "";
      this.manager = null;
      this.noManager = false;
    },

    async handleSubmitForm() {
      if(!this.noManager && !this.manager?.is_active && !confirm(this. __('Confirmar atribuição a gestor bloqueado?'))) {
        return;
      }

      const selectedLeads = this.selectedLeads.map(lead => lead.id);

      this.loading_submit = true;
      try {
          await assign_manager(selectedLeads, this.manager?.id, this.noManager);
          this.$listeners.submit();
          this.$store.commit("alert", this.__('Salvo!'));
      } catch (error) {
          this.$store.commit("error", error);
      } finally {
          this.loading_submit = false;
          this.handleClose();
      }
    },

    async getManagers() {
      this.managers = [];
      this.loading_managers = true;
      try {
        const response = await indexManagers({
          filter: this.search
        });
        this.managers = response.data
      } catch (error) {
        this.$store.commit("error", error);
      } finally {
        this.loading_managers = false;
      }
    },


    clearFilter() {
      this.search = "";
      this.getManagers();
    },
  },
  computed: {
    canUpdateManager() {
      return this.$store.getters['auth/getScope']('notifications.supervision.assign_manager')
    },
    disableSubmit() {
      if (!this.noManager) return !this.manager || !this.canUpdateManager;

      return !this.canUpdateManager;
    }
  },
  watch: {
    value(value) {
      if (value) this.getManagers()
    }
  },
  created() {
  }
}
</script>

<style>

</style>