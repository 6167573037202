var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticStyle:{"max-width":"1400px"}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-card-title',{staticClass:"text-h4 mb-10"},[_vm._v(" "+_vm._s(_vm.__('Supervisão'))+" "),_c('v-btn',{attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.getNotificationsByManager()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-row',{staticClass:"mb-10",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"image"}}):(_vm.notifications_total)?_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.setAllActiveDrawer}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.notifications_total.unread)+" ")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.__('Todas ativas'))+" ")]),_c('v-list-item-subtitle',{staticClass:"mt-1"},[_c('SectionBar',{attrs:{"label":_vm.__('Detalhes'),"items":[
                                                {
                                                    color: '#059862',
                                                    value: _vm.notifications_total.per_period.green.perc,
                                                    label: '0-1 ' + _vm.__('Dia'),
                                                    total:  _vm.notifications_total.per_period.green.total
                                                },
                                                {
                                                    color: '#ffc107',
                                                    value: _vm.notifications_total.per_period.orange.perc,
                                                    label: '2-4 ' + _vm.__('Dias'),
                                                    total: _vm.notifications_total.per_period.orange.total
                                                },
                                                {
                                                    color: '#f55246',
                                                    value: _vm.notifications_total.per_period.red.perc,
                                                    label: '+5 ' + _vm.__('Dias'),
                                                    total:  _vm.notifications_total.per_period.red.total
                                                },
                                            ]}})],1)],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"image"}}):(_vm.without_manager)?_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.setNoManagerDrawer}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.without_manager.unread)+" ")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.__('Sem responsável'))+" ")]),_c('v-list-item-subtitle',{staticClass:"mt-1"},[_c('SectionBar',{attrs:{"label":_vm.__('Detalhes'),"items":[
                                                {
                                                    color: '#059862',
                                                    value: _vm.without_manager.per_period.green.perc,
                                                    label: '0-1 ' + _vm.__('Dia'),
                                                    total: _vm.without_manager.per_period.green.total
                                                },
                                                {
                                                    color: '#ffc107',
                                                    value: _vm.without_manager.per_period.orange.perc,
                                                    label: '2-4 ' + _vm.__('Dias'),
                                                    total: _vm.without_manager.per_period.orange.total
                                                },
                                                {
                                                    color: '#f55246',
                                                    value: _vm.without_manager.per_period.red.perc,
                                                    label: '+5 ' + _vm.__('Dias'),
                                                    total: _vm.without_manager.per_period.red.total
                                                },
                                            ]}})],1)],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"},on:{"click":_vm.setBlockedManagerDrawer}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"image"}}):(_vm.with_blocked_manager)?_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.with_blocked_manager.unread)+" ")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.__('Com responsável bloqueado'))+" ")]),_c('v-list-item-subtitle',{staticClass:"mt-1"},[_c('SectionBar',{attrs:{"label":_vm.__('Detalhes'),"items":[
                                                {
                                                    color: '#059862',
                                                    value: _vm.with_blocked_manager.per_period.green.perc,
                                                    label: '0-1 ' + _vm.__('Dia'),
                                                    total: _vm.with_blocked_manager.per_period.green.total
                                                },
                                                {
                                                    color: '#ffc107',
                                                    value: _vm.with_blocked_manager.per_period.orange.perc,
                                                    label: '2-4 ' + _vm.__('Dias'),
                                                    total: _vm.with_blocked_manager.per_period.orange.total
                                                },
                                                {
                                                    color: '#f55246',
                                                    value: _vm.with_blocked_manager.per_period.red.perc,
                                                    label: '+5 ' + _vm.__('Dias'),
                                                    total: _vm.with_blocked_manager.per_period.red.total
                                                },
                                            ]}})],1)],1)],1):_vm._e()],1)],1),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table: table-thead, table-tbody"}}):_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.__('Gestor'))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.__('Leads'))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.__('Notificações'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.__('Status'))+" ")])])]),(_vm.notifications_by_manager)?_c('tbody',_vm._l((_vm.notifications_by_manager),function(manager){return _c('tr',{key:manager.id,staticClass:"link",on:{"click":function($event){return _vm.setManagerDrawer(manager)}}},[(manager.manager)?_c('td',[_c('span',{class:{ 'text-decoration-line-through': !manager.manager.user.is_active }},[_vm._v(_vm._s(manager.manager.user.name))])]):_c('td',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.__('Sem gestor'))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(manager.leads)+" ")]),_c('td',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"font-weight-bold blue--text"},'span',attrs,false),on),[_vm._v(_vm._s(manager.unread_groups))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.__('Notificações agrupadas')))])]),_c('v-icon',{attrs:{"left":"","right":"","small":""}},[_vm._v("mdi-chevron-right")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(manager.unread))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.__('Total de notificações')))])])],1),_c('td',{attrs:{"with":"200px"}},[_c('SectionBar',{attrs:{"label":_vm.__('Detalhes'),"items":[
                                            {
                                                color: '#059862',
                                                value: manager.per_period.green.perc,
                                                label: '0-1 ' + _vm.__('Dia'),
                                                total: manager.per_period.green.total
                                            },
                                            {
                                                color: '#ffc107',
                                                value: manager.per_period.orange.perc,
                                                label: '2-4 ' + _vm.__('Dias'),
                                                total: manager.per_period.orange.total
                                            },
                                            {
                                                color: '#f55246',
                                                value: manager.per_period.red.perc,
                                                label: '+5 ' + _vm.__('Dias'),
                                                total: manager.per_period.red.total
                                            },
                                        ]}})],1)])}),0):_vm._e()])],1)],1)],1)],1),_c('notification-leads-drawer',{attrs:{"title":_vm.drawer_title,"manager":_vm.drawer_manager},on:{"close":_vm.closeDrawer,"getData":_vm.drawer_get_data,"refresh":_vm.getNotificationsByManager},model:{value:(_vm.drawer_open),callback:function ($$v) {_vm.drawer_open=$$v},expression:"drawer_open"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }