import Vue from "vue";

export async function analytics_notifications_by_manager() {
    const response = await Vue.prototype.$http.get(`/supervision/analytics/notifications_by_manager`);

    return response.data
}

export async function all_notifications(params) {
    const response = await Vue.prototype.$http.get(`/supervision/notifications`, {
        params
    });

    return response.data
}

export async function without_manager_notifications(params) {
    const response = await Vue.prototype.$http.get(`/supervision/notifications`, {
        params: {
            no_manager: true,
            ...params
        }
    });

    return response.data
}

export async function with_blocked_manager_notifications(params) {
    const response = await Vue.prototype.$http.get(`/supervision/notifications`, {
        params: {
            blocked_manager: true,
            ...params
        }
    });

    return response.data
}

export async function notifications_by_manager(manager_id, params) {
  const response = await Vue.prototype.$http.get(`/supervision/notifications/manager/${manager_id}`, {
      params
  });

  return response.data
}

export async function assign_manager(leads, manager_id = null, no_manager = false) {
  const response = await Vue.prototype.$http.post(`/supervision/notifications/assign_manager`, {
      leads,
      manager_id,
      no_manager
  });

  return response.data
}
