<template>
  <v-navigation-drawer
    :value="value"
    @input="handleChange"
    absolute
    right
    temporary
    :width="700"
  >
    <v-card>
      <v-card-title>
        <v-icon left @click="closeDrawer">mdi-close</v-icon>
        <span class="text-truncate">{{ title }}</span>
      </v-card-title>

      <v-sheet>
        <v-row class="ml-2 py-2">
          <v-col v-if="selectedLeads.length" cols="3">
            <div style="width: 150px;">
              <span class="grey--text">
                {{selectedLeads.length}} {{ __('Selecionado(s)') }}
              </span>
              <v-btn @click="selectedLeads = []" icon small>
                <v-icon small>mdi-close-circle</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col class="text-right">
            <v-btn color="info" text small :disabled="value.length < 1" @click="openManagerDialog">
              {{ __('Atribuir responsável') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-sheet>

      <notification-leads-table
        name="drawer-notifications-table"
        v-model="selectedLeads"
        :options.sync="options"
        :loading="fetching"
        :items="items"
        :totalItems="totalItems"
        :hiddenColumns="hiddenColumns"
      >
      </notification-leads-table>
    </v-card>

    <assign-manager-dialog
      v-model="managerDialog"
      :selectedLeads="selectedLeads"
      @submit="handleSubmitAssignManager"
    />
  </v-navigation-drawer>
</template>

<script>
import NotificationLeadsTable from '@/components/Supervision/NotificationLeadsTable';
import AssignManagerDialog from '@/components/Supervision/AssignManagerDialog';
import i18n from '@/mixins/i18n';

export default {
  name: 'notification-leads-drawer',
  components: { NotificationLeadsTable, AssignManagerDialog },
  mixins: [i18n],
  props: {
    value: {
      type: Boolean
    },
    title: {
      type: String
    },
    leads: {
      type: Array,
      default: () => []
    },
    manager: {
      type: Object
    }
  },
  data() {
    return {
      i18n_prefix: 'supervision',
      selectedLeads: [],
      items: [],
      options: {
        sortBy: ["first_notification_date"],
        sortDesc: [false],
        itemsPerPage: 15
      },
      totalItems: 0,
      fetching: false,
      loading_error: false,

      first_load: false,

      managerDialog: false
    };
  },
  methods: {
    handleChange($event) {
      this.$emit("input", $event);
    },
    closeDrawer() {
      this.$emit("input", false);
      this.resetStates();
      if (this.$listeners.close) this.$listeners.close();
    },

    resetStates() {
      this.items = [];
      this.selectedLeads = [];
      this.options = {
        sortBy: ["first_notification_date"],
        sortDesc: [true],
        itemsPerPage: 15
      };
      this.totalItems = 0;
      this.loading_error = false;
      this.first_load = false;
    },
    openManagerDialog() {
      this.managerDialog = true;
    },
    handleSubmitAssignManager() {
      this.loadData();
      if (this.$listeners.refresh) this.$listeners.refresh();
    },
    async loadData() {
      if (this.$listeners.getData) {
        this.first_load = true;
        this.fetching = true;
        try {
          const response = await this.$listeners.getData(this.options);
          this.items = response.data;
          this.totalItems = response.meta.total;
        } catch(error) {
          this.loading_error = true;
        } finally {
          this.fetching = false;
        }
      }
    }
  },
  computed: {
    hiddenColumns() {
      if (this.manager != null) {
        return ['manager']
      }

      return [];
    }
  },
  watch: {
    value(open) {
      if (!open) this.closeDrawer();
      if (open) this.loadData();
    },
    options: {
      handler() {
        if (this.first_load)
          this.loadData();
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>

</style>