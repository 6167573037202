<template>
    <v-list :dense="dense">
        <v-list-item v-if="!items.length">
            <v-list-item-content>
                <v-list-item-title class="text-center">
                    {{ __('Sem notificações', 'notifications') }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <div v-for="(item, index) in items" :key="item.id">
            <v-list-item 
              :class="{ 'primary--text': !isRead(item), 'grey--text': isRead(item) }" 
              :to="getRoute(item.lead.identifier_code, item.type)" 
              :dense="dense"
            >
                <v-list-item-avatar :title="__('Grupo de notificações', 'notifications')" :size="dense ? 20 : 30">
                    <v-icon :title="__('Grupo', 'notifications')" v-if="item.group" :color="isRead(item) ? '': 'primary'" :small="dense">
                        mdi-forum
                    </v-icon>
                    <v-icon title="Único" v-else :color="isRead(item) ? '': 'primary'" :small="dense">
                        mdi-message
                    </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title class="text-wrap">
                        {{ item.title }}
                    </v-list-item-title>

                    <v-list-item-subtitle v-if="!hideMessage">
                        {{ item.message }}
                    </v-list-item-subtitle>

                    <v-list-item-subtitle v-if="!isRead(item)">
                        {{ item.created_at_diff }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                        {{ item.created_at }}
                    </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <div>
                        <v-menu v-if="isRead(item) && !item.group" offset-y bottom>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn v-bind="attrs" @click.prevent.stop="on.click" icon x-small>
                                    <v-icon>
                                        mdi-information
                                    </v-icon>
                                </v-btn>
                            </template>

                            <v-list dense>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>
                                            {{ __('Data recebimento', 'notifications') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-title>
                                            {{ item.created_at }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>
                                            {{ __('Data visualização', 'notifications') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-title>
                                            {{ item.dismissed_at }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>
                                            {{ __('Tempo de vida', 'notifications') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-title>
                                            {{ item.livetime }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>
                                            {{ __('Visto por', 'notifications') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-title>
                                            {{ item.dismissed_by.name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-btn v-if="!isRead(item) && checkable" @click.prevent.stop="$emit('click:dismiss', item)" color="primary" icon small title="Visto">
                            <v-icon>mdi-check-circle</v-icon>
                        </v-btn>
                    </div>
                </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index + 1 < items.length" class="ml-12"></v-divider>
        </div>
    </v-list>
</template>

<script>
import i18n from '@/mixins/i18n';
import { getRoute } from '@/services/lead_notifications';

export default {
    mixins: [i18n],
    props: {
        items: Array,
        dense: Boolean,
        hideMessage: Boolean,
        allRead: Boolean,
        checkable: Boolean
    },

    methods: {
        handleClickItem(item) {
            this.$emit('click:item', item);
        },
        isRead(item) {
            if(this.allRead) return true;

            return item.read;
        },
        getRoute(lead_number, type) {
          return getRoute(lead_number, type)
        }
    },
}
</script>