<style lang="scss" scoped>
    .bar {
        display: flex;
        background-color: #ccc;
        width: 100%;
        height: 10px;
        border-radius: 5px;
        overflow: hidden;
        
        .section {
            height: 100%;
            text-align: center;
            color: white;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.red {
                background-color: red;
            }
            &.orange {
                background-color: orange;
            }
            &.green {
                background-color: green;
            }
        }
    }
</style>

<template>
    <div>
        <div @click="show" @mouseenter="show" @mouseleave="menu = false" class="bar">
            <div 
                v-for="(item, index) in items" :key="index" 
                class="section"    
                :style="{ 
                    width: item.value + '%',
                    'background-color': item.color || '#ccc'
                }" 
            />
        </div>
        <v-menu v-model="menu" absolute :position-x="x" :position-y="y">
            <v-list dense width="200">
                <v-subheader class="text-uppercase">{{ label }}</v-subheader>
                <div v-for="(item, index) in items" :key="index">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>
                                <v-icon small :color="item.color" class="mr-2">mdi-circle</v-icon> 
                                {{ item.label }}:
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="caption text-right">
                            {{ item.total }}
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider class="ml-10"></v-divider>
                </div>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    props: {
        items: Array,
        label: String
    },

    data() {
        return {
            menu: false,
            x: 0,
            y: 0,
        }
    },

    methods: {
        show(e) {
            e.preventDefault();
            this.menu = false;
            this.x = e.clientX;
            this.y = e.clientY + 10;
            this.$nextTick(() => {
                this.menu = true;
            })
        }
    }
}
</script>